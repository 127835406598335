<template>
  <div class="container">
    <div>
      <span class="headline">措施关闭情况</span>
      <el-button type="primary" class="el-icon-document-add" plain @click="handelTobeSaved">待保存</el-button>
    </div>
    <el-table
      :data="tableData.slice(start, end)"
      ref="multipleTable"
      border
      class="table"
      :header-row-style="{color:'#000000'}"
      :height="elementHeight"
      highlight-current-row
      @selection-change="handleSelectionChange"
      @row-click="rowClick"
      @select="selectChange"
      @select-all="selectAll"
      :cell-style="cellStyle"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="serial" label="提问编号"></el-table-column>
      <el-table-column prop="auditResult" label="审核结果"></el-table-column>
      <el-table-column prop="score" label="得分"></el-table-column>
      <el-table-column prop="reson" label="原因">
        <template slot-scope="scope">
          <el-input v-model="scope.row.reson"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="measure" label="措施">
        <template slot-scope="scope">
          <el-input v-model="scope.row.measure"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="deadline" label="时限">
        <template slot-scope="scope">
          <!-- <el-select v-model="scope.row.deadline" clearable>
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :value="item.id"
              :label="item.typeName"
            ></el-option>
          </el-select>-->
          <el-input v-model="scope.row.deadline"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="dutyUserName" label="责任人">
        <template slot-scope="scope">
          <el-input v-model="scope.row.dutyUserName"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            @click="handleSubmit(scope.row)"
            style="border-radius: 15px !important;"
          >提交措施</el-button>
          <el-button
            type="primary"
            plain
            @click="handleValid(scope.row)"
            style="border-radius: 15px !important;"
          >有效性验证</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      class="_Pagination"
    ></el-pagination>
  </div>
</template>

<script>
import { get, put } from "../../api/http";
export default {
  data() {
    return {
      tableData: [],
      elementHeight: 0,
      typeList: [],
      // 分页
      start: 0,
      end: 10,
      currentPage: 1,
      pageSize: 10,
      auditType: this.$route.query.auditType,
      PlanId: this.$route.query.planId,
      count: 0,
      multipleSelection: [],
      form: {},
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    let aside = document.querySelector(".aside");
    aside.style.display = "none";
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 30 + 46 + 48);
      });
    },
    //获取措施关闭情况
    getList() {
      let url =
        this.auditType == 0
          ? "/api/VdaChecklisttracksub?PlanId=" + this.PlanId
          : "/api/VdaChecklisttrack?PlanId=" + this.PlanId;
      get(url).then((res) => {
        this.tableData = res.data;
        this.count = res.count;
      });
    },
    // 待保存按钮
    handelTobeSaved() {
      if (this.form.id != null) {
        let url =
          this.auditType == 0
            ? "/api/VdaChecklisttracksub/UpdateOne"
            : "/api/VdaChecklisttrack/UpdateOne";
        put(url, this.form).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: "请选择需要保存的数据",
        });
      }
    },
    // 提交措施按钮
    handleSubmit(row) {
      let url =
        this.auditType == 0
          ? "/api/VdaChecklisttracksub/UpdateTwo"
          : "/api/VdaChecklisttrack/UpdateTwo";
      put(url, row).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "提交成功",
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 有效性验证按钮
    handleValid(row) {
      let url =
        this.auditType == 0
          ? "/api/VdaChecklisttracksub/UpdateThree"
          : "/api/VdaChecklisttrack/UpdateThree";
      put(url + "?Id=" + row.id + "&" + "IsEffect=" + row.isEffect).then(
        (res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "验证成功",
            });
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 单击表格
    rowClick(row) {
      this.form = row;
      this.$refs.multipleTable.toggleRowSelection(row);
      this.selectChange(this.multipleSelection);
    },
    selectChange(selection) {
      if (selection.length > 1) {
        const del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    cellStyle() {
      return "cursor:pointer;";
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.start = (this.currentPage - 1) * this.pageSize;
      this.end = this.start + this.pageSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.pageSize;
      this.end = this.start + this.pageSize;
    },
  },
  destroyed() {
    let aside = document.querySelector(".aside");
    aside.style.display = "block";
  },
};
</script>

<style lang="less" scoped>
.headline {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-right: 10px;
}
.table {
  margin-top: 16px;
  ::v-deep .el-table__row td {
    border-bottom: 1px solid #e8e9ebdb !important;
  }
}
._Pagination {
  text-align: end;
  padding: 10px 20px;
  background-color: #ffffff;
}
</style>